
import '@fortawesome/fontawesome-free/css/all.css'
// todo: can we reduce bundle size by only including the fontawesome icons we use?
// import { faAirFreshener} from '@fortawesome/free-solid-svg-icons'
// console.log(faAirFreshener)

// Bootstrap scripts
import 'bootstrap'

// Both the static html pages and react-bootstrap use the bootstrap css.
// The static html pages currently don't use bootstrap's javascript.
import './styles.scss'
